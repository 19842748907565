<script>
import DatePicker from "vue2-datepicker";
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import simplebar from "simplebar-vue";
import {queryHoliday} from '@/apis/holiday'
import {formatDate} from '@/apis/common'
import {queryServiceOrderByDate} from '@/apis/serviceorder'

export default {
  page: {
    title: "Schedule Plan",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout,simplebar,DatePicker},
  data() {
    return {
      title: "Schedule Plan",
      daterange:[],
      holidayList:[],
      dayList:[],
      serviceDataList:[],
      auditDataList:[
        {
          audit:"Carol",
          name:"carol",
          travelDay:0,
          workDay:0,
          dayList:[]
        },
        {
          audit:"Huey",
          name:"huey",
          travelDay:0,
          workDay:0,          
          dayList:[]
        },
        {
          audit:"Ali",
          name:"ali",
          travelDay:0,
          workDay:0,          
          dayList:[]
        },
        {
          audit:"Feifei",
          name:"feifei",
          travelDay:0,
          workDay:0,          
          dayList:[]
        },{
          audit:"Jack",
          name:"jack",
          travelDay:0,
          workDay:0,          
          dayList:[]
        },{
          audit:"Hieu",
          name:"hieu",
          travelDay:0,
          workDay:0,          
          dayList:[]
        },{
          audit:"Loan",
          name:"loan",
          travelDay:0,
          workDay:0,          
          dayList:[]
        },{
          audit:"Sally",
          name:"Sally",
          travelDay:0,
          workDay:0,          
          dayList:[]
        },                
        {
          audit:"Zhan Jin",
          name:"jin",
          travelDay:0,
          workDay:0,          
          dayList:[]
        }
      ],
      form:{
        startDate:"",
        endDate:"",
        country:"CN"
      }
    };
  },
  computed: {
  },
  watch:{
  },
  created(){
    this.initDateRange();
    this.init();
  },
  mounted() {
  },
  methods: {
    async init(){
      this.loadData()
    },
    getParams(){
      if(this.daterange.length==2)
        {
          this.form.startDate = this.daterange[0]
          this.form.endDate = this.daterange[1]
        }      
        let params = {...this.form}
        return params
    },    
    loadData(){
        const params = this.getParams();
        queryHoliday(params).then((res)=>{
            this.holidayList = res.data;
            queryServiceOrderByDate(params).then((res)=>{
              this.formatDayList();
              this.formatAuditList(res.data);
            })
        });
    },
    formatAuditList(data){
      this.serviceDataList.splice(0)
      for(let i=0;i<data.length;i++){
        let startTimestamp = new Date(data[i].serviceDate).getTime()
        let endTimestamp = new Date(data[i].serviceDateEnd).getTime()
        const isPending = data[i].serviceStatus=="SC"?true:false
        //
        if(startTimestamp==endTimestamp){
          //一人天
          if(data[i].worker!=""){
            // 主审
            if(data[i].travelOption!=null &&data[i].travelOption!="" &&data[i].travelOption.indexOf("1")!=-1){
              this.serviceDataList.push({...data[i],travelDay:true,audit:data[i].worker,day:formatDate(new Date(startTimestamp-3600 * 1000 * 24),"yyyy-MM-dd"),scheduleText:"Travel",isPending:isPending,isObserver:false})
            }
            this.serviceDataList.push({...data[i],travelDay:false,audit:data[i].worker,day:data[i].serviceDate,scheduleText:data[i].serviceNo+"/"+data[i].client.clientName+"/Leader",isPending:isPending,isObserver:false})
            if(data[i].travelOption!=null &&data[i].travelOption!="" &&data[i].travelOption.indexOf("2")!=-1){
              this.serviceDataList.push({...data[i],travelDay:true,audit:data[i].worker,day:formatDate(new Date(startTimestamp+3600 * 1000 * 24),"yyyy-MM-dd"),scheduleText:"Travel",isPending:isPending,isObserver:false})
            }            
          }
          if(data[i].dataWorker!=""){
            // 组员
            if(data[i].dataWorkerTravelOption!=null &&data[i].dataWorkerTravelOption!="" &&data[i].dataWorkerTravelOption.indexOf("1")!=-1){
              this.serviceDataList.push({...data[i],travelDay:true,audit:data[i].dataWorker,day:formatDate(new Date(startTimestamp-3600 * 1000 * 24),"yyyy-MM-dd"),scheduleText:"Travel",isPending:isPending,isObserver:false})
            }
            this.serviceDataList.push({...data[i],travelDay:false,audit:data[i].dataWorker,day:data[i].serviceDate,scheduleText:data[i].serviceNo+"/"+data[i].client.clientName+"/Member",isPending:isPending,isObserver:false})
            if(data[i].dataWorkerTravelOption!=null &&data[i].dataWorkerTravelOption!="" &&data[i].dataWorkerTravelOption.indexOf("2")!=-1){
              this.serviceDataList.push({...data[i],travelDay:true,audit:data[i].dataWorker,day:formatDate(new Date(startTimestamp+3600 * 1000 * 24),"yyyy-MM-dd"),scheduleText:"Travel",isPending:isPending,isObserver:false})
            }            
          }          
          if(data[i].observer!=""){
            // 观察者
            if(data[i].observerTravelOption!=null &&data[i].observerTravelOption!="" &&data[i].observerTravelOption.indexOf("1")!=-1){
              this.serviceDataList.push({...data[i],travelDay:true,audit:data[i].observer,day:formatDate(new Date(startTimestamp-3600 * 1000 * 24),"yyyy-MM-dd"),scheduleText:"Travel",isPending:isPending,isObserver:true})
            }
            this.serviceDataList.push({...data[i],travelDay:false,audit:data[i].observer,day:data[i].serviceDate,scheduleText:data[i].serviceNo+"/"+data[i].client.clientName+"/Observer",isPending:isPending,isObserver:true})
            if(data[i].observerTravelOption!=null &&data[i].observerTravelOption!="" &&data[i].observerTravelOption.indexOf("2")!=-1){
              this.serviceDataList.push({...data[i],travelDay:true,audit:data[i].observer,day:formatDate(new Date(startTimestamp+3600 * 1000 * 24),"yyyy-MM-dd"),scheduleText:"Travel",isPending:isPending,isObserver:true})
            }            
          }           
        }else{
          for(let t = startTimestamp;t<=endTimestamp;t+=3600 * 1000 * 24){
            if(data[i].worker!=""){
              // 主审
              if(t == startTimestamp && data[i].travelOption!=null &&data[i].travelOption!="" &&data[i].travelOption.indexOf("1")!=-1){
                this.serviceDataList.push({...data[i],travelDay:true,audit:data[i].worker,day:formatDate( new Date(t-3600 * 1000 * 24),"yyyy-MM-dd"),scheduleText:"Travel",isPending:isPending,isObserver:false})
              }
              this.serviceDataList.push({...data[i],travelDay:false,audit:data[i].worker,day:formatDate(new Date(t),"yyyy-MM-dd"),scheduleText:data[i].serviceNo+"/"+data[i].client.clientName+"/Leader",isPending:isPending,isObserver:false})
              if(t==endTimestamp && data[i].travelOption!=null &&data[i].travelOption!="" &&data[i].travelOption.indexOf("2")!=-1){
                this.serviceDataList.push({...data[i],travelDay:true,audit:data[i].worker,day:formatDate(new Date(t+3600 * 1000 * 24),"yyyy-MM-dd"),scheduleText:"Travel",isPending:isPending,isObserver:false})
              }            
            }
            if(data[i].dataWorker!=""){
              // 组员
              if(t == startTimestamp && data[i].dataWorkerTravelOption!=null &&data[i].dataWorkerTravelOption!="" &&data[i].dataWorkerTravelOption.indexOf("1")!=-1){
                this.serviceDataList.push({...data[i],travelDay:true,audit:data[i].dataWorker,day:formatDate(new Date(t-3600 * 1000 * 24),"yyyy-MM-dd"),scheduleText:"Travel",isPending:isPending,isObserver:false})
              }
              this.serviceDataList.push({...data[i],travelDay:false,audit:data[i].dataWorker,day:formatDate(new Date(t),"yyyy-MM-dd"),scheduleText:data[i].serviceNo+"/"+data[i].client.clientName+"/Member",isPending:isPending,isObserver:false})
              if(t==endTimestamp && data[i].dataWorkerTravelOption!=null &&data[i].dataWorkerTravelOption!="" &&data[i].dataWorkerTravelOption.indexOf("2")!=-1){
                this.serviceDataList.push({...data[i],travelDay:true,audit:data[i].dataWorker,day:formatDate( new Date(t+3600 * 1000 * 24),"yyyy-MM-dd"),scheduleText:"Travel",isPending:isPending,isObserver:false})
              }            
            }          
            if(data[i].observer!=""){
              // 观察者
              if(t == startTimestamp && data[i].observerTravelOption!=null &&data[i].observerTravelOption!="" &&data[i].observerTravelOption.indexOf("1")!=-1){
                this.serviceDataList.push({...data[i],travelDay:true,audit:data[i].observer,day:formatDate(new Date(t-3600 * 1000 * 24),"yyyy-MM-dd"),scheduleText:"Travel",isPending:isPending,isObserver:true})
              }
              this.serviceDataList.push({...data[i],travelDay:false,audit:data[i].observer,day:formatDate(new Date(t),"yyyy-MM-dd"),scheduleText:data[i].serviceNo+"/"+data[i].client.clientName+"/Observer",isPending:isPending,isObserver:true})
              if(t==endTimestamp && data[i].observerTravelOption!=null &&data[i].observerTravelOption!="" &&data[i].observerTravelOption.indexOf("2")!=-1){
                this.serviceDataList.push({...data[i],travelDay:true,audit:data[i].observer,day:formatDate( new Date(t+3600 * 1000 * 24),"yyyy-MM-dd"),scheduleText:"Travel",isPending:isPending,isObserver:true})
              }            
            }      
          }
        }
      }
      //清空数据
      for(let i=0;i<this.auditDataList.length;i++){
        const auditName = this.auditDataList[i].name
        this.auditDataList[i].dayList.splice(0) 
        let travelDays = 0
        let workDay = 0 
        for(let j=0;j<this.dayList.length;j++){
          const day = this.dayList[j].text
          let auditDayData = this.serviceDataList.filter(item=>{ return item.audit==auditName && item.day==day}) 
          if(auditDayData.length>0){
            if(auditDayData[0].travelDay){
              travelDays+=1
            }else{
              workDay+=1
            }
          }     
          this.auditDataList[i].dayList.push({day:day,list:auditDayData})
        }
        this.auditDataList[i].travelDay = travelDays
        this.auditDataList[i].workDay = workDay
      }
    },
    formatDayList(){
      let startTimestamp = new Date(this.form.startDate).getTime()
      let endTimestamp = new Date(this.form.endDate).getTime()
      this.dayList.splice(0)
      for(let t = startTimestamp;t<=endTimestamp;t+=3600 * 1000 * 24){
        const text = formatDate(new Date(t),"yyyy-MM-dd");
        this.dayList.push({
          text:text,
          isHoliday:this.checkHoliday(text)
        })
      }
    },
    checkHoliday(dateStr){
      let result = false
      for(let i=0;i<this.holidayList.length;i++){
        if(this.holidayList[i].holiday==dateStr){
          result = true
          break;
        }
      }
      return result
    },
    initDateRange(){ 
      // 获取当前时间
      let currentDate = new Date();      
      let year = currentDate.getFullYear();
      let month = currentDate.getMonth();
      let date = currentDate.getDate();      
      let dayOfWeek = currentDate.getDay();    
      // 计算本周的周一日期
      let startDate = new Date(year, month, date - dayOfWeek + 1);           
      var endTimestamp = startDate.getTime() + 3600 * 1000 * 24 * 30;
      let endDate = new Date(endTimestamp);
      this.daterange.push(formatDate(startDate,"yyyy-MM-dd"))
      this.daterange.push(formatDate(endDate,"yyyy-MM-dd"))
    },
    rightMove(){
      const scrollContainer = this.$refs.schedultTable.$el.querySelector('.simplebar-content-wrapper');
      const scrollLeft = scrollContainer.scrollLeft;
      scrollContainer.scrollTo({
              left: scrollLeft +980,
              behavior: 'smooth',
      });
    },
    leftMove(){
      const scrollContainer = this.$refs.schedultTable.$el.querySelector('.simplebar-content-wrapper');
      const scrollLeft = scrollContainer.scrollLeft;
      scrollContainer.scrollTo({
              left: scrollLeft - 980,
              behavior: 'smooth',
      });
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row mt-4">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <date-picker
                      v-model="daterange"
                      format="YYYY-MM-DD"
                      value-type="format"
                      range
                      append-to-body
                      lang="en"
                      confirm
                      placeholder="Select date"
                    ></date-picker>
                    </div>
                    <div class="col-lg-8">
                      <button type="button" class="btn btn-info" @click="loadData">View</button>                      
                      <button type="button" class="btn btn-light ms-1" @click="leftMove"> 左移</button>

                      <button type="button" class="btn btn-light ms-1" @click="rightMove">右移</button>
                    </div>
                  </div>
                  <div class="table-responsive" id="schedultTable">
                    <simplebar class="h-100"
                      ref="schedultTable"
                    >
                      <table class="v-schedule" cellspacing="2" >
                        <thead>
                          <tr class="v-header">
                            <td class="v-header-item fixed-column">验证员</td>
                            <td class="v-header-item v-date" v-for="(day,index) of dayList" 
                            :key="index"
                            :class="{
                                  'holiday': day.isHoliday,
                              }"
                            >{{day.text}}</td>                       
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="v-data"  v-for="(audit,index) of auditDataList" :key=index>
                            <td class="v-data-item fixed-column">{{audit.audit}}({{audit.workDay}},{{audit.travelDay}})</td>
                            <td class="v-data-item" v-for="day in audit.dayList" 
                            :key=audit.name+day.day
                            :class="{
                                  'travel-day': day.list.length==1&&day.list[0].travelDay,
                                  'audit-day':day.list.length==1&&!day.list[0].travelDay&&!day.list[0].isObserver,
                                  'audit-observer-day':day.list.length>0&&!day.list[0].travelDay&&day.list[0].isObserver,
                                  'half-day':day.list.length>1,
                                  'is-pending':day.list.length==1&&day.list[0].isPending,
                              }"
                            >
                            <span v-if="day.list.length==1&&day.list[0].travelDay" :title="day.list[0].scheduleText">{{day.list[0].scheduleText}}</span>
                            <span v-if="day.list.length==1&&!day.list[0].travelDay" :title="day.list[0].scheduleText">{{day.list[0].scheduleText}}</span>
                            <div v-if="day.list.length>1" class="audit-day" :title="day.list[0].scheduleText"
                            :class="{
                              'is-pending':day.list.length>1&&day.list[0].isPending,
                            }"
                              >{{day.list[0].scheduleText}}</div>
                            <div v-if="day.list.length>1" class="audit-day last" :title="day.list[1].scheduleText"
                              :class="{
                              'is-pending':day.list.length>1&&day.list[1].isPending,
                            }"
                            >{{day.list[1].scheduleText}}</div>
                            </td>                                           
                          </tr>                                                                                         
                        </tbody>
                    </table>                      
                    </simplebar>
                  </div>                                        
                </div>
            </div>
        </div><!-- end col -->
    </div><!-- end row -->
  </Layout>
</template>
<style lang="scss" scoped>
.v-schedule{
  margin-top: 10px;
  border: #e9ecef solid 1px;
  font-size: 10px;
  .fixed-column {
    position: sticky;
    left: 0;
    background-color: #f2f2f7;
    z-index: 10;
    font-weight: bold;
    min-width: 115px!important;
    width: 115px!important;
  }  
  .v-header{
    .v-header-item{
      min-width: 140px;
      width: 140px;
      height: 40px;
      line-height: 40px;
      border-top: #e9ecef solid 1px;
      border-left: #e9ecef solid 1px;
      text-align: center;
      font-weight: bold;
    }
    .holiday{
      background-color: #e9ecef;
    }
  }
  .v-data{
    .v-data-item{
      width: 140px;
      height: 70px;
      overflow: hidden;
      border-top: #e9ecef solid 1px;
      border-left: #e9ecef solid 1px;
      text-align: center;     
      span{
        padding: 5px;
      }            
    }
    .half-day{
      display: flex;
      flex-direction: column;
      justify-content: center;
      div{
        flex: 1;
        height: 35px;
        padding: 5px;         
        overflow: hidden;
      }
      .last{
        border-top: #e9ecef solid 1px;
      }
    }
    .travel-day{
        background-color: #f8b425;
        color: white;
      }       
      .audit-day{
        background-color: #38a4f8;
        color: white;
      }      
      .audit-observer-day{
        background-color: #9b38f8;
        color: white;
      }        
      .is-pending{
        background-color: rgb(2, 164, 153) !important;
        color: white !important;
      }          
  }  
}
.border-light {
  border-color: #f8f7fc !important;
}
.bg-light {
    background-color: rgba(248, 247, 252,1)!important;
}
</style>